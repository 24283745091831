<template>
  <layout-auth>
    <div class="px-138">
      <div class="my-96">
        <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
      </div>
      <app-heading-1 class="">Reset Password</app-heading-1>
      <app-normal-text class="mb-2">for {{email}}</app-normal-text>
      <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
      <validation-observer ref="resetForm" >
        <div class="row">
          <div class="col-12">
            <validation-provider #default="{ errors }" name="Password" vid="password" rules="required|validPassword">
              <app-password-input label="Create Password" v-model="newPassword" placeholder="Enter password" :errors="errors"></app-password-input>
            </validation-provider>
          </div>
          <div class="col-12">
            <validation-provider #default="{ errors }" name="Confirm password" vid="confirm_password" rules="required">
              <app-password-input label="Confirm Password" v-model="confirmPassword" @input="sameAs" placeholder="Confirm password" :errors="[...errors, ...passwordError]"></app-password-input>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
              <app-simple-button variant="primary" :loading="loading" class="mb-16" @click="resetPassword">Set New Password</app-simple-button>
              <app-normal-text class="align-middle">
                <b-link to="/admin/login"><feather-icon icon="ChevronLeftIcon" size="20"/> Back to log in</b-link>
              </app-normal-text>
          </div>
        </div>
      </validation-observer>
    </div>
  </layout-auth>
</template>

<script>
import LayoutAuth from '@/layouts/layout-auth.vue'
import {
  BLink,BFormCheckbox, BFormGroup, BAlert
} from 'bootstrap-vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from '@validations'
import {reset_password} from '@/apis/auth'
extend('validPassword', value=>{
    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    if(regex.test(value)){
      return true
    }else{
      return 'Password should be 8 characters long and contain atleast one capital letter and special character'
    }
  })
export default {
  components: {
    LayoutAuth,
    BAlert,
    BLink,
    BFormCheckbox,
    BFormGroup,
    AppHeading1,
    AppNormalText,
    AppTextInput,
    AppPasswordInput,
    AppSimpleButton,
    ValidationProvider, 
    ValidationObserver
  },
  data() {
    return {
      email: this.$route.params.email,
      newPassword:'',
      confirmPassword:'',
      loading:false,
      errorMessage:'',
      required,
      passwordError:[]       
    }
  },
  methods: {
    resetPassword() {
      this.errorMessage=''
      this.$refs.resetForm.validate().then(success=>{
        if(!success || this.passwordError.length){
          this.errorMessage='Please fill all the required fields & enter valid data'
          return
        }
        this.loading=true
        let data={
            email:this.email,
            newPassword:this.newPassword,
            token:this.$route.params.token,
        }
        reset_password(data).then(resp=>{
            this.loading=false
            this.$bvToast.toast(resp.message, {
              title: 'Password Updated',
              variant: 'success',
              solid: true,
            })
        setTimeout(()=>{this.$router.push('/admin/login')},[1000])
        }).catch(err=>{
          this.loading=false
          this.errorMessage = err.message
        })
      })
    },
    sameAs(){
      if(this.newPassword==this.confirmPassword){
        this.passwordError = []
      }else{
        this.passwordError = ['Passwords do not match']
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
